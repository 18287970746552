body {
  background-color: #050d12;
  color: #ffffff;
}

h1 {
  margin: auto;
  margin-top: 20px;
  font-size: 40px;
  width: 40%;
  text-align: center;
}


table {
  width: 0%;
  margin: auto;
  border: #1d385f 8px solid;
  border-radius: 7px;
}

#random_box {
  margin: auto;
  margin-top: 20px;
  width: 160px;
}

.random {
  text-align: center;
  font-size: 20px;
}

#randomPic {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

caption {
  text-align: left;
  font-size: 18px;
}

td {}

.champCard {
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  padding: 4px;
}

.champCard:Hover {
  background-color: #17345d
}


img {
  border-radius: 5px;

}

button {
  width: 160px;
  height: 70px;
  background-color: #da8135;
  border-radius: 5%;
  color: white;
  font-family: "Beaufort for LOL", serif;
  font-size: 20px;
  cursor: pointer;
}

button:hover {
  box-shadow: 2px 2px 4px 4px rgba(255, 255, 255, 0.24);
  border: #28292a 5px solid;
}